<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="treeFilterText" placeholder="输入关键字查询"></el-input>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              node-key="id"
              :props="defaultProps"
              ref="tree"
              @node-click="treeNodeClick"
              :highlight-current="true"
              :filter-node-method="filterNode"
          >
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-sys__user">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <el-input v-model="dataForm.realName" placeholder="姓名" style="width: 140px" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.username" placeholder="登录账号" style="width: 160px" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.mobile" placeholder="手机号码" style="width: 160px" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group style="margin-top: -3px;">
                <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                <toolbar-button role="add" v-if="$hasPermission('sys:participantuser:save')" @click="addOrUpdateHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('sys:participantuser:delete')" @click="deleteHandle()"></toolbar-button>
                <toolbar-button role="export" v-if="$hasPermission('sys:participantuser:export')" @click="exportHandle()"></toolbar-button>
                <!--<toolbar-button icon="el-icon-refresh-right" name="刷新缓存" v-if="$hasPermission('sys:participantuser:cache')" @click="cacheRealNames()"></toolbar-button>-->
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table
              v-loading="dataListLoading"
              :data="dataList" border style="width: 100%;"
              highlight-current-row
              @selection-change="dataListSelectionChangeHandle"
              @sort-change="dataListSortChangeHandle"
              :cell-style="{padding: '2px 0'}">
            <el-table-column type="selection" align="center" width="50"></el-table-column>
            <el-table-column prop="realName" label="姓名" sortable="custom" width="150">
              <template v-slot="{row}">
                <el-tag v-if="row.typeId === 3" type="warning">{{ row.realName }}</el-tag>
                <el-tag v-else-if="row.typeId === 2">{{ row.realName }}</el-tag>
                <el-tag v-else type="danger">{{ row.realName }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="username" label="登录账号" sortable="custom" width="150"></el-table-column>
            <el-table-column prop="deptName" label="所属单位"></el-table-column>
            <el-table-column prop="mobile" label="手机号码" sortable="custom" align="center" width="150"></el-table-column>
            <el-table-column prop="status" label="用户状态" sortable="custom" align="center" width="100">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status === 0" size="small" type="danger">{{ $t('user.status0') }}</el-tag>
                <el-tag v-else size="small" type="success">{{ $t('user.status1') }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" align="center" width="210">
              <template slot-scope="scope">
                <table-button role="update" v-if="$hasPermission('sys:participantuser:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('sys:participantuser:delete')" @click="deleteHandle(scope.row.id)"></table-button>
                <table-button icon="el-icon-setting" name="项目权限" status="success" v-if="$hasPermission('sys:participantuser:update')" @click="openAuthorityHandle(scope.row.id)"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 新增 / 修改 -->
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
          <!-- 项目、合同标段、工区权限分配 -->
          <user-authority v-if="authorityVisible" ref="authority" @close="closeAuthorityHandle"></user-authority>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './participantuser-add-or-update'
import UserAuthority from './user-authority'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/participantUser/page',
        getDataListIsPage: true,
        deleteURL: '/sys/participantUser',
        exportURL: '/sys/participantUser/export',
      },
      postList: [],
      dataForm: {
        prjId: '',
        realName: '',
        username: '',
        deptId: '',
        postId: '',
        gender: '',
        mobile: ''
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      treeFilterText: '',
      treeLoading: false,
      authorityVisible: false,
    }
  },
  components: {
    AddOrUpdate,
    UserAuthority
  },
  created() {
    // this.getDataList()
    // this.getDeptTreeData()
    // this.getPostList()
  },
  watch: {
    treeFilterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    prjChangeHandle() {
      this.dataForm.deptId = ''
      this.getDataList()
      this.getDeptTreeData()
    },
    openAuthorityHandle(userId) {
      this.authorityVisible = true;
      this.$nextTick(() => {
        this.$refs.authority.userId = userId
        this.$refs.authority.init()
      })
    },
    closeAuthorityHandle() {
      this.authorityVisible = false;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        if (!id) {
          this.$refs.addOrUpdate.dataForm.deptId = this.dataForm.deptId
        }
        this.$refs.addOrUpdate.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.init()
      })
    },
    getDeptTreeData() {
      this.treeLoading = true
      this.$http.get(
          '/sys/participantDept/list',
          {
            params: {
              prjId: this.dataForm.prjId,
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          this.$message.error(res.msg)
          return
        }
        this.treeData = res.data
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    getPostList() {
      this.$http.get('/sys/post/list').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.postList = res.data
      }).catch(() => {
      })
    },
    // 将所有用户的ID、REAL_NAME缓存起来，方便其他微服务根据ID直接获取到姓名
    // cacheRealNames () {
    //   return this.$confirm(`确定要重新缓存所有用户姓名吗？`).then(() => {
    //     this.$http.get('/sys/participantUser/cacheRealNames').then(({ data: res }) => {
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg)
    //       }
    //       this.$message.success('已重新缓存。')
    //     }).catch(() => {})
    //   }).catch((result) => {
    //   })
    // },
    treeNodeClick(data, node, ele) {
      // 连续选中同一节点，则右侧查询全部参建用户数据
      if (this.dataForm.deptId == data.id) {
        this.dataForm.deptId = ''
        this.$refs.tree.setCurrentKey(null)
      } else {
        this.dataForm.deptId = data.id
      }
      this.getDataList()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
  }
}
</script>
<style scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 180px);
}
</style>
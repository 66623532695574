<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree class="left-tree" v-loading="treeLoading" node-key="id" ref="myTree"
            :data="treeData"
            :props="defaultTreeProps"
            :expand-on-click-node="false"
            :highlight-current="true"
            @node-click="treeNodeClick" default-expand-all>
            <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="mouseEnter(data,node)" @mouseleave="mouseLeave(data,node)">
              <span>{{ node.label }}</span>
              &nbsp;
              <span>
                <el-button v-if="$hasPermission('opm:monthlyschedule:delete')" v-show="data.show" type="text" size="mini" @click.stop="() => deleteByYmHandle(data)">
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__monthlyschedule">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <el-input v-model="dataForm.scheduleUnitName" placeholder="形象工程名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
                <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                <toolbar-button role="add" v-if="$hasPermission('opm:monthlyschedule:save')" @click="batchAddHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('opm:monthlyschedule:delete')" @click="deleteHandle()"></toolbar-button>
                <toolbar-button name="根据年计划自动生成" icon="el-icon-s-grid" v-if="$hasPermission('opm:monthlyschedule:generate')" @click="generateFromYearHandle"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
            <el-table-column label="年月" width="100" header-align="center" align="center" fixed="left">
              <template v-slot="{row}">{{row.y + '年' + row.m + '月'}}</template>
            </el-table-column>
            <el-table-column prop="scheduleUnitName" label="形象单元" min-width="150" fixed="left" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="unitName" label="单位" width="100" align="center" fixed="left" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="num" label="计划工程量" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="amount" label="计划金额(元)" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startDate" label="开始日期" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="endDate" label="结束日期" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" align="center" width="190">
              <template v-slot="{row}">
                <table-button role="update" v-if="$hasPermission('opm:monthlyschedule:update')" @click="addOrUpdateHandle(row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('opm:monthlyschedule:delete')" @click="deleteHandle(row.id)"></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:monthlyschedule:update'))"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 新增 / 修改 -->
          <my-batch-add v-if="myBatchAddVisible" ref="myBatchAdd" @refreshDataList="batchAddRefreshDataListHandle" @close="closeMyBatchAddDialogHandle"></my-batch-add>
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './monthlyschedule-add-or-update'
import MyBatchAdd from './monthlyschedule-batch-add'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/monthlySchedule/page',
        getDataListIsPage: true,
        exportURL: '/opm/monthlySchedule/export',
        deleteURL: '/opm/monthlySchedule',
      },
      dataForm: {
        prjId: '',
        contractId: '',
        y: null, // 月计划 年份
        m: null, // 月计划 月份
        scheduleUnitName: '', // 形象单元 名称
      },
      myBatchAddVisible: false,
      lastSelectedTreeId: '',

      treeLoading: false,
      treeData: [],
      defaultTreeProps: {
        children: 'children',
        label: 'name'
      },
    }
  },
  watch: {},
  components: {
    AddOrUpdate,
    MyBatchAdd
  },
  methods: {
    // 合同标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.refreshDataList()
    },

    refreshDataList () {
      this.dataForm.y = ''
      this.dataForm.m = ''
      this.getDataList()
      this.loadTreeData()
    },

    // 加载进度月计划的年份月份树
    loadTreeData () {
      this.treeLoading = true
      // 清空数据
      this.treeData = []
      this.$refs.myTree.store.nodesMap = []

      this.$http.get('/opm/monthlySchedule/getYmList',
        {
          params: {
            'contractId': this.dataForm.contractId
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let resultData = res.data || []
        if (resultData.length <= 0) {
          this.treeData = resultData
        } else {
          // 前端拼接树形结构 按照年月分组的树形数据结构
          let myTree = this.$refs.myTree
          for (let i = 0; i < resultData.length; i++) {
            let data = resultData[i]
            let y = data['y'] ? data['y'] + '' : ''
            let m = data['m'] ? data['m'] + '' : ''
            let yId = y
            let mId = y + m
            let yearNode = myTree.getNode(yId)
            if (!yearNode) {
              myTree.append({
                id: yId,
                name: y + "年",
                y: y,
                m: '',
                children: []
              },null)
            }
            let monthNode = myTree.getNode(mId)
            if (!monthNode) {
              myTree.append({
                id: mId,
                name: m + "月",
                y: y,
                m: m,
                children: []
              },yId)
            }
          }
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },

    // 批量新增 月计划
    batchAddHandle () {
      this.myBatchAddVisible = true
      this.$nextTick(() => {
        this.$refs.myBatchAdd.dataForm.id = ''
        this.$refs.myBatchAdd.dataForm.prjId = this.dataForm.prjId
        this.$refs.myBatchAdd.dataForm.contractId = this.dataForm.contractId
        this.$refs.myBatchAdd.init()
      })
    },

    batchAddRefreshDataListHandle (data) {
      this.dataForm.y = data.year || ''
      this.dataForm.m = data.month || ''
      this.getDataList()
      this.loadTreeData()
    },

    //  年月节点点击事件
    treeNodeClick (data,node,ele) {
      // 点击高亮节点则取消高亮
      if (this.lastSelectedTreeId == (data.id + this.dataForm.contractId)) {
        this.lastSelectedTreeId = ''
        this.dataForm.y = ''
        this.dataForm.m = ''
        this.$refs.myTree.setCurrentKey(null)
      } else {
        this.dataForm.y = data.y
        this.dataForm.m = data.m

        this.lastSelectedTreeId = data.id + this.dataForm.contractId
      }
      // 查询数据
      this.getDataList()
    },

    closeMyBatchAddDialogHandle () {
      this.myBatchAddVisible = false
    },

    // 月计划根据年份月份删除
    deleteByYmHandle (data) {
      if (data) {
        this.$confirm(`您确定要删除${data.y}年${data.m}月的计划吗？`,this.$t('prompt.title'),{
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true,
            text: '删除中...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          this.$http.delete(
            `/opm/monthlySchedule/deleteByYm`,
            {
              'data': {
                contractId: this.dataForm.contractId,
                y: data.y,
                m: data.m
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.refreshDataList()
              }
            })
          }).catch(() => {
            // do nothing
          }).finally(() => {
            loading.close()
          })
        }).catch(() => {
          // do nothing
        })
      }
    },

    mouseEnter (data,node) {
      // 叶子节点才显示
      if (node.isLeaf) {
        this.$set(data,'show',true)
      }
    },

    mouseLeave (data,node) {
      if (node.isLeaf) {
        this.$set(data,'show',false)
      }
    },

    // 根据年计划明细生成月度计划
    generateFromYearHandle(){
      this.$confirm("您确定要根据年计划生成月度计划吗？", this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '运行中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.post(
          `/opm/monthlySchedule/generateFromYear`,
          {
            contractId: this.dataForm.contractId,
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.refreshDataList()
            }
          })
        }).catch(() => {
          // do nothing
        }).finally(() => {
          loading.close()
        })
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 180px);
  }
  ::v-deep .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
</style>